import HexController from 'controllers/hex_controller';

export default class TestingGoalFormController extends HexController {
  static targets = ['textarea', 'lengthWarningLabel', 'redWarningDot', 'submitButton', 'clearButton'];

  _initialValue = null;

  static values = {
    minLength: Number
  }

  connect() {
    this._initialValue = this.textareaTarget.value;
    this.update();
  }

  update() {
    if (this._initialValue === this.textareaTarget.value) {
      this.submitButtonTarget.disabled = true;
      this.clearButtonTarget.classList.add('hidden');
    } else {
      this.submitButtonTarget.disabled = false;
      this.clearButtonTarget.classList.remove('hidden');
    }
    if (this.textareaTarget.value.split(/\s/).filter(Boolean).length < this.minLengthValue) {
      this.lengthWarningLabelTarget.classList.remove('hidden');
      this.redWarningDotTarget.classList.remove('hidden');
    } else {
      this.lengthWarningLabelTarget.classList.add('hidden');
      this.redWarningDotTarget.classList.add('hidden');
    }
  }

  reset() {
    this.textareaTarget.value = this._initialValue;
    this.update();
  }

  submitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this._initialValue = this.textareaTarget.value;
    this.element.submit();
    this.update();
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      if (event.ctrlKey) {
        event.preventDefault()
        this.submitForm(event);
      }
    }
  }
}
