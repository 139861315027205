import HexController from 'controllers/hex_controller'

export default class SuggestParamValuesButtonController extends HexController {
  static values = {
    planId: Number,
    parameterId: Number,
    url: String,
    canShow: Boolean
  };

  async click(_event) {
    if (!this.canShowValue) {
      HW.dialogs.showDesignGuidanceDialog();
      return;
    }
    try {
      window.sembiAILoadingIndicator.activate();
      const response = await fetch(this.urlValue)
      if (response.status < 200 || response.status >= 300) {
        window.sembiAILoadingIndicator.deactivate();
      }
    } catch (error) {
      window.sembiAILoadingIndicator.activate();
    }
  }
}
