import HexController from 'controllers/hex_controller'
import { v4 as uuidv4 } from 'uuid';

export default class extends HexController {
  static targets = ['values', 'name', 'loadingIndicator', 'suggestions', 'generateButton', 'response', 'discardButton', 'generateParameterSpan', 'generateVariationsSpan'];
  static values = {
    url: String,
    canShow: Boolean
  };
  _loadingTimer = null;
  _response = null;
  _lastRequestUuid = null;
  _previousValues = null;

  connect() {
    // Only if the generate button is present
    if (this.hasGenerateButtonTarget) {
      // Unfortunately, the nameTarget doesn't trigger input events on change, so we need to listen to the combobox input instead
      document.getElementById('parameter_name-new').addEventListener('input', e => this._maybeShowGenerateButton(e?.target?.value));
      this.valuesTarget.addEventListener('input', e => this._switchGenerateButtonSpan(e));
      this.element.addEventListener('submit', () => {
        window.navigationLockController?.setOnBeforeUnload();
      });
    }
  }

  generate() {
    if (!this.canShowValue) {
      Modalbox.hide();
      setTimeout(() => {
        HW.dialogs.showDesignGuidanceDialog();
      }, 100);
      return;
    }
    this.suggestionsTarget.classList.add('hidden');
    this.suggestionsTarget.classList.remove('error');
    const name = this.nameTarget.value;
    const values = this.valuesTarget.value;
    this.startLoading();
    this._lastRequestUuid = uuidv4();
    this._response = null;
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        name,
        values,
        request_uuid: this._lastRequestUuid,
        // authenticity_token: this.element.elements['authenticity_token'].value
      })
    }).catch(() => {
      this.stopLoading();
    });
    // Stop loading after 90 seconds
    this._loadingTimer = setTimeout(() => {
      this.stopLoading();
      this._resetSuggestionsArea();
    }, 90000);
  }

  _switchGenerateButtonSpan() {
    if (this.valuesTarget.value.trim().length > 0) {
      this.generateParameterSpanTarget.classList.add('hidden');
      this.generateVariationsSpanTarget.classList.remove('hidden');
    } else {
      this.generateParameterSpanTarget.classList.remove('hidden');
      this.generateVariationsSpanTarget.classList.add('hidden');
    }
  }

  _maybeShowGenerateButton(value) {
    // Do not show/hide the button if we are waiting for a response or showing suggestions
    if (!this._loadingTimer && !this._response) {
      if (value?.length >= 3) {
        this.generateButtonTarget.classList.remove('hidden');
      } else {
        this.generateButtonTarget.classList.add('hidden');
      }
      this._switchGenerateButtonSpan();
    }
  }

  _resetSuggestionsArea() {
    this.valuesTarget.classList.remove('highlight-border');
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.classList.add('hidden');
    this.discardButtonTarget.classList.add('hidden');
    this._previousValues = null;
    this._response = null;
    this._lastRequestUuid = null;
    this._loadingTimer = null;
    this._maybeShowGenerateButton(this.nameTarget.value);
  }

  _updateSuggestions() {
    const existingParams = this.valuesTarget.value.split('\n').map(v => v.trim()).filter(Boolean);
    const newParams = this._response.values.filter((v) => {
      return Boolean(v) && !existingParams.includes(v);
    });
    if (existingParams.length > 0) {
      existingParams.push('');
    }
    const paramsList = [...new Set([...existingParams, ...newParams])]
    this.valuesTarget.value = `${paramsList.join('\n')}\n`;
    this.valuesTarget.classList.add('highlight-border');
    this.suggestionsTarget.classList.remove('hidden');
    this.suggestionsTarget.innerHTML = `Suggested values:<br/> - ${newParams.join('<br/> - ')}`;
    this.generateButtonTarget.classList.add('hidden');
  }

  responseTargetConnected() {
    try {
      this._response = JSON.parse(this.responseTarget.textContent);
    } catch (e) {
      this._response = null;
    }
    clearTimeout(this._loadingTimer);
    this._loadingTimer = null;
    this.stopLoading();

    this.valuesTarget.classList.remove('highlight-border');
    if (this._response?.request_uuid?.length > 0) {
      // Render the responses only if this was the last request, if the user managed to start a new request in the meantime, ignore it
      if (this._lastRequestUuid === this._response.request_uuid) {
        if (this._response.message?.length > 0) {
          this.suggestionsTarget.innerHTML = this._response.message;
        }
        if (this._response.status === 'error') {
          // Show an error message
          this.suggestionsTarget.classList.add('error');
          this.suggestionsTarget.classList.remove('hidden');
          this.generateButtonTarget.classList.remove('hidden');
        } else {
          window.navigationLockController?.setOnBeforeUnload();
          // Apply the suggestions received
          this._previousValues = this.valuesTarget.value;
          this._updateSuggestions();
          // Focus the values textarea and place the cursor at the end
          this.valuesTarget.focus();
          this.valuesTarget.selectionStart = this.valuesTarget.selectionEnd = this.valuesTarget.value.length;
          this.discardButtonTarget.classList.remove('hidden');
        }
      }
    } else {
      this._resetSuggestionsArea();
    }
  }

  startLoading() {
    this.loadingIndicatorTarget.classList.remove('hidden');
    this.generateButtonTarget.classList.add('hidden');
    if (this.hasResponseTarget) {
      this.responseTarget?.remove();
    }
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.classList.add('hidden');
    this.discardButtonTarget.classList.add('hidden');
  }

  stopLoading() {
    this.loadingIndicatorTarget.classList.add('hidden');
  }

  discard() {
    this.valuesTarget.value = this._previousValues;
    this._resetSuggestionsArea();
  }
}
