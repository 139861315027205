import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = {
    index: String,
    remainingValues: String,
  }

  connect() {
    // On Turbo submit end, replace the current parameter in all the forms
    this.element.addEventListener('turbo:submit-end', () => this.submitEnded());
  }

  submitEnded() {
    // Replace all the forms containing suggested parameter index with the remaining values
    document.querySelectorAll(`input[type="hidden"].suggested-parameter-${this.indexValue}`).forEach(element => {
      element.value = this.remainingValuesValue;
    });
  }
}
