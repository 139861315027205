import HexController from 'controllers/hex_controller';
import {filesize} from 'filesize';

function expiresIn(expiration) {
  if (expiration <= 0) {
    return '';
  }
  const now = Math.floor(new Date().getTime() / 1000)
  if (expiration <= now) {
    return 'now';
  }
  const expirationInMinutes = Math.floor((expiration - now) / 60);
  if (expirationInMinutes < 60) {
    return `in ${expirationInMinutes + 1} minute${expirationInMinutes + 1 > 1 ? 's' : ''}`;
  }
  const expirationInHours = Math.floor(expirationInMinutes / 60);
  if (expirationInHours < 24) {
    return `in ${expirationInHours + 1} hour${expirationInHours + 1 > 1 ? 's' : ''}`;
  }
  const expirationInDays = Math.floor(expirationInHours / 24);
  if (expirationInDays < 30) {
    return `in ${expirationInDays + 1} day${expirationInDays + 1 > 1 ? 's' : ''}`;
  }
  const expirationInMonths = Math.floor(expirationInDays / 30);
  return `in ${expirationInMonths + 1} month${expirationInMonths + 1 > 1 ? 's' : ''}`;
}

const TABS = {
  UPLOAD_FILES: 'uploadFilesTab',
  ENTER_CONTENT: 'enterContentTab'
}

export default class extends HexController {
  static targets = [
    'filesInput',
    'filesContainer',
    'loadingFilesSpinner',
    'uploadFilesArea',
    'redWarningDot',
    'filesLoadingFailed',
    'fileNameInput',
    'fileContentInput',
    'submitButton',
    'uploadFilesTab',
    'enterContentTab',
    'enterContentTabBtn',
    'uploadFilesTabBtn'
  ];

  static values = {
    retrieveUrl: String,
    deleteUrl: String,
    getFilesUrl: String
  };

  _uploads = [];
  _currentTab = TABS.ENTER_CONTENT;
  _expiration = 0;

  connect() {
    this.getFiles();
    this.dragCounter = 0;
    this.setButtonDisabled();
    this.showTab(TABS.UPLOAD_FILES);
  }

  setFilesExpiration(expiration) {
    this._expiration = expiration;
  }

  filesLoadingFailed() {
    this.loadingFilesSpinnerTarget.classList.add('hidden');
    this.filesLoadingFailedTarget.classList.remove('hidden');
  }

  getFiles() {
    this.loadingFilesSpinnerTarget.classList.remove('hidden');
    this.filesLoadingFailedTarget.classList.add('hidden');
    fetch(this.getFilesUrlValue).then(response => {
      response.json().then(data => {
        if (data.length === 0) {
          this.loadingFilesSpinnerTarget.innerHTML = 'No files found';
        } else {
          this.loadingFilesSpinnerTarget.classList.add('hidden');
          data.forEach(file => {
            const row = this.fileRow(file.key.split('/').at(-1), file.size, this.deleteFileLink(file.key), parseInt(file.expiration));
            this.filesContainerTarget.prepend(row);
          });
        }
        this.maybeShowRedWarningDot();
      }).catch(error => {
        console.error('Error parsing get files response', error);
        this.filesLoadingFailed();
      });
    }).catch(error => {
      console.error('Error getting files', error);
      this.filesLoadingFailed();
    });
  }

  uploadFilesClicked() {
    this.filesInputTarget.click();
  }

  deleteFileLink(key) {
    return `
      <a class='file-delete-button' data-controller='tooltip' data-tooltip-title-value='${_('Delete this Document')}' href='#' data-key='${key}' data-action='click->sembi-ai--system-files-form#deleteFile'>
        <i class='material-icons delete'></i>
      </a>
    `;
  }

  fileRow(fileName, fileSize, status, expiration) {
    const row = document.createElement('div');
    const expirationString = expiresIn(expiration);
    const roundedDate = new Date((expiration || 0) * 1000);
    roundedDate.setMinutes(roundedDate.getMinutes() >= 30 ? 60 : 0, 0, 0);
    const expiringAtString = roundedDate.toLocaleTimeString(undefined, { hour: 'numeric' });
    const expiringOnString = expiration > 0 ? `Expiring on ${roundedDate.toLocaleDateString()} at ${expiringAtString}` : ''
    row.classList.add('file-row');
    row.innerHTML = `
      <div class="file-row-left">
        <div class="file-name">${fileName}</div>
        <div class="file-size" data-controller='tooltip' data-tooltip-title-value='${expiringOnString}'>${filesize(fileSize)}${expiration > 0 ? `, expires ${expirationString}` : ''}</div>
      </div>
      <div class="file-row-right">
        <div class="file-status">${status}</div>
      </div>
    `;
    return row;
  }

  maybeShowRedWarningDot() {
    const fileRowsCount = this.filesContainerTarget.querySelectorAll('.file-row').length;
    const succeededUploadsCount = this._uploads.filter(upload => upload.uploaded && !upload.failed && !upload.deleted).length;
    if (fileRowsCount > 0 && (this._uploads.length === 0 || succeededUploadsCount > 0)) {
      this.redWarningDotTarget.classList.add('hidden');
    } else {
      this.redWarningDotTarget.classList.remove('hidden');
    }
  }

  uploadFile(file) {
    const now = Math.floor(new Date().getTime() / 1000);
    const expiration = this._expiration > 0 ? (now + (this._expiration * 24 * 60 * 60)) : 0;
    const row = this.fileRow(file.name, file.size, 'Uploading...', expiration - 1);
    this._uploads.push({
      file,
      expiration,
      row
    });
    this.filesContainerTarget.prepend(row);
    if (file.size > 100 * 1024 * 1024) {
      this.filesContainerTarget.querySelector('.file-row').querySelector('.file-status').innerHTML = 'File too large';
      return;
    }
    this.redWarningDotTarget.classList.add('hidden');
    this.loadingFilesSpinnerTarget.classList.add('hidden');
    const uploadIndex = this._uploads.length - 1;
    fetch(this.retrieveUrlValue,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        expiration: `${expiration > 0 ? expiration : ''}`,
        filename: file.name
      })
    }).then(response => {
      response.json().then(data => {
        // Upload the file to the presigned url
        fetch(data.url, {
          method: 'PUT',
          body: file,
          mode: 'cors'
        }).then(response => {
          const fileStatus = this._uploads[uploadIndex].row.querySelector('.file-status')
          if (response.status >= 200 && response.status < 300) {
            this._uploads[uploadIndex].uploaded = true;
            fileStatus.innerHTML = this.deleteFileLink(data.key);
          } else {
            response.text().then(body => {
              console.error('Error uploading file:', response.status, body);
              fileStatus.innerHTML = 'File upload failed';
              row.classList.add('error');
            });
            this._uploads[uploadIndex].failed = true;
            this.maybeShowRedWarningDot();
          }
        }).catch(error => {
          console.error('Error uploading file', error);
          this._uploads[uploadIndex].row.querySelector('.file-status').innerHTML = 'File upload failed';
          this._uploads[uploadIndex].failed = true;
          this.maybeShowRedWarningDot();
        });
      }).catch(error => {
        console.error('Error parsing presigned url response', error);
        this._uploads[uploadIndex].row.querySelector('.file-status').innerHTML = 'File upload failed';
        this._uploads[uploadIndex].failed = true;
        this.maybeShowRedWarningDot();
      });
    }).catch(error => {
      console.error('Error getting presigned url', error);
      this._uploads[uploadIndex].row.querySelector('.file-status').innerHTML = 'File upload failed';
      this._uploads[uploadIndex].failed = true;
      this.maybeShowRedWarningDot();
    });
  }

  uploadFiles(eventOrFiles) {
    const files = eventOrFiles.target?.files || eventOrFiles;
    window.my_upload_files = files;
    for (const file of files) {
      this.uploadFile(file);
    }
  }

  deleteFile(event) {
    event.preventDefault();
    const key = event.target.closest('a.file-delete-button').dataset.key;
    const outerRow = event.target.closest('.file-row');
    const filename = outerRow.querySelector('.file-name').innerHTML;
    outerRow.querySelector('.file-status').innerHTML = 'Deleting...';
    fetch(this.deleteUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({key})
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        outerRow.remove();
        if (this.filesContainerTarget.querySelectorAll('.file-row').length === 0) {
          this.loadingFilesSpinnerTarget.classList.remove('hidden');;
          this.loadingFilesSpinnerTarget.innerHTML = "No files found"
        }
        this._uploads.forEach(upload => {
          if (upload.file.name === filename) {
            upload.deleted = true;
          }
        });
      } else {
        outerRow.querySelector('.file-status').innerHTML = 'File delete failed';
      }
      this.maybeShowRedWarningDot();
    }).catch(error => {
      console.error(`Error deleting file:`, error)
      outerRow.querySelector('.file-status').innerHTML = 'File delete failed';
      this.maybeShowRedWarningDot();
    });
  }

  /* Drang and drop handling */
  updateDragLabel() {
    if (this.dragCounter > 0) {
      this.uploadFilesAreaTarget.classList.add('dragover');
    } else {
      this.uploadFilesAreaTarget.classList.remove('dragover');
    }
  }

  dragover(e) {
    e.preventDefault();
  }

  dragenter(e) {
    e.preventDefault();
    this.dragCounter++;
    this.updateDragLabel();
  }

  dragleave(e) {
    e.preventDefault();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.updateDragLabel();
    }
  }

  drop(e) {
    e.preventDefault();
    this.dragCounter = 0;
    this.updateDragLabel();
    this.uploadFiles(e.dataTransfer.files);
  }

  // Add system file via content handlers
  setButtonDisabled() {
    this.submitButtonTarget.disabled = this.fileNameInputTarget.value.length === 0 || this.fileContentInputTarget.value.length === 0;
  }

  filenameChanged() {
    this.setButtonDisabled();
  }

  filecontentChanged() {
    this.setButtonDisabled();
  }

  createFileClicked() {
    const file = new File([this.fileContentInputTarget.value], this.fileNameInputTarget.value, { type: 'text/plain' });
    this.uploadFile(file);
    this.fileNameInputTarget.value = '';
    this.fileContentInputTarget.value = '';
    this.setButtonDisabled();
  }

  // Tab handling
  showEnterContentTab() {
    this.showTab(TABS.ENTER_CONTENT);
  }

  showUploadFilesTab() {
    this.showTab(TABS.UPLOAD_FILES);
  }

  showTab(tab) {
    this[`${this._currentTab}Target`].classList.add('hidden');
    this[`${tab}Target`].classList.remove('hidden');
    this[`${this._currentTab}BtnTarget`].classList.remove('active');
    this[`${tab}BtnTarget`].classList.add('active');
    this._currentTab = tab;
  }
}
