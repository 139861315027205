import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = {
    url: String,
    exec: String,
    canShow: Boolean
  }

  click(event) {
    event.preventDefault();
    if (this.execValue === 'new') {
      HW.utils.handleParameterNewClick(this.urlValue);
    } else if (this.execValue === 'coverage-goal') {
      if (this.canShowValue) {
        HW.utils.handleShowAISuggestionsClick(this.urlValue);
      } else {
        HW.dialogs.showDesignGuidanceDialog();
      }
    } else if (this.execValue === 'model-generate') {
      if (this.canShowValue) {
        window.sembiAILoadingIndicator.activate();
        fetch(this.urlValue, {
          method: 'GET',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        }).catch(error => {
          console.error('ModalButtonController#click :: error:', error);
          window.sembiAILoadingIndicator.deactivate();
        });
      } else {
        HW.dialogs.showDesignGuidanceDialog();
      }
    }
  }
}
