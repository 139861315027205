import HexController from "controllers/hex_controller";

export default class LoadingIndicatorController extends HexController {
  static values = { active: Boolean };
  static targets = ["text"];

  _timeout = null;
  _timeoutDuration = 60000;

  texts = [
    "Generating AI suggestions...",
    "Processing powerful predictions...",
    "Processing powerful possibilities...",
    "Teaching the robots to think...",
    "Nurturing neural networks...",
    "Testing technical truths...",
    "Computing clever concepts...",
    "Brewing binary brilliance...",
    "Summoning silicon secrets...",
    "Consulting the AI oracle...",
    "Spinning up the idea engine...",
    "Calibrating cosmic cognition...",
    "Composing clever concepts...",
    "Computing clever calculations...",
    "Decoding cosmic inspiration...",
    "Awakening neural networks...",
    "Weaving algorithmic magic...",
    "Cooking up test design ideas...",
    "Weaving neural wisdom...",
    "Harmonizing AI thoughts...",
    "Designing digital daydreams...",
    "Distilling digital dreams...",
    "Synthesizing smart signals...",
    "Mining machine muses...",
    "Crystallizing cyber thoughts..."
  ];

  connect() {
    this._setActiveClass();
    window.sembiAILoadingIndicator = this;
    if (this.activeValue) {
      this._setRandomText();
    }
  }

  deactivate() {
    this.activeValue = false;
    this._clearTimeout();
  }

  _setRandomText() {
    this.textTarget.textContent = this.texts[Math.floor(Math.random() * this.texts.length)];
  }

  activate() {
    this.activeValue = true;
    this._setRandomText();
    this._startTimeout();
  }

  activeValueChanged(value) {
    this._setActiveClass();
  }

  _setActiveClass() {
    if (this.activeValue) {
      this.element.classList.add("active");
    } else {
      this.element.classList.remove("active");
    }
    this.setScrollLock();
  }

  setScrollLock() {
    if (this.activeValue) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  _clearTimeout() {
    clearTimeout(this._timeout);
    this._timeout = null;
  }

  _startTimeout() {
    this._clearTimeout();
    this._timeout = setTimeout(() => {
      this.deactivate();
    }, this._timeoutDuration);
  }
}
