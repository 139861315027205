import HexController from 'controllers/hex_controller';

export default class extends HexController{
  connect() {
    this.element.addEventListener('click', (event) => this.onclick(event));
  }

  onclick(_event) {
    if (this.element.tagName.toLowerCase() === 'i') {
      this.replaceWithSpinner(this.element);
    } else {
      this.replaceWithSpinner(this.element.querySelector('i.material-icons'));
    }
  }

  replaceWithSpinner(el) {
    if (el) {
      el.className = 'material-icons autorenew active';
    }
  }
};
